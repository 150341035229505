import axios from 'axios';

export const loadProducts = ({ commit, state }) => {
  commit("SET_LOADING_STATE",true);
  let outdata = state.searchdata;
  axios
    .post("/apiecom/ecommerce/products/getProducts",outdata)
    .then((response) => {
      let products = response.data.data;
      let totalP = response.data.totalPages;
      commit("SET_PRODUCTS", products);
      commit("SET_TOTAL_PAGES",totalP);
      commit("SET_LOADING_STATE",false);
    })
    .catch(() => {
      commit("SET_LOADING_STATE",false);
    });
}

export const loadProduct = ({ commit }, id) => {
  commit("SET_LOADING_STATE",true);
  axios
    .get(`/apiecom/ecommerce/products/getProduct/${id}`)
    .then((response) => {
      let product = response.data.data;
      commit("SET_PRODUCT", product);
      commit("SET_LOADING_STATE",false);
    })
    .catch(() => {
      commit("SET_LOADING_STATE",false);
    });
}

export const loadFeatured = ({ commit}) => {
  axios
    .get("/apiecom/ecommerce/products/getFeatured")
    .then((response) => {
      commit("SET_FEATURED", response.data.data);
    })
    .catch((error) => {
      console.log(error);
    });
}

export const loadCategories = ({ commit }) => {
  axios
    .get("/apiecom/ecommerce/getCategoryTree")
    .then((response) => {
      let categories = response.data.data;
      commit("SET_CATEGORIES", categories);
    })
    .catch((error) => {
      console.log(error);
    });

    axios
    .get("/apiecom/ecommerce/getCategoryList")
    .then((response) => {
      let categories = response.data.data;
      commit("SET_CATEGORYLIST", categories);
    })
    .catch((error) => {
      console.log(error);
    });
}
export const loadConfiguration = ({ commit }) => {
    axios
      .get(
        "/apiecom/ecommerce/getConfiguration"
      )
      .then((response) => {
        let configuration = response.data.data;
        configuration.contact = JSON.parse(configuration.contact);
        configuration.networks = JSON.parse(configuration.networks);
        configuration.fillers = JSON.parse(configuration.fillers);
        configuration.styles = JSON.parse(configuration.styles);
        document.getElementById('favicon').setAttribute('href',configuration.images.favIcon);
        document.title = (configuration.page_title) ? configuration.page_title : 'Tienda virtual';

        commit("SET_CONFIGURATION", configuration);
        commit("SET_ALERTS", configuration.fillers.alerts);
      })
      .catch((error) => {
        console.log(error);
      });
}

export const loadAttributeList = ({ commit },filters) => {
  axios.post("/apiecom/ecommerce/getAttributeList",filters)
        .then((response) => {
            let atts = response.data.data;
            commit("SET_ATTRIBUTES", atts);
        })
        .catch((error) => {
            console.log(error);
        });
}

export const loadBrands = ({ commit }) => {
  axios
    .get(
      "/apiecom/ecommerce/getBrands"
    )
    .then((response) => {
      let brands = response.data.data;
      commit("SET_BRANDS", brands);
    })
    .catch((error) => {
      console.log(error);
    });
}

export const loadCatalog = ({ commit, state }, data) => {
    commit("SET_LOADING_STATE",true);
    let outdata = state.searchdata;
  axios
    .post("/apiecom/ecommerce/catalog/" + `${data.uid}`,outdata)
    .then((response) => {
      let catalog = response.data.data;
      let totalP = response.data.totalPages;
      commit("SET_CATALOG", catalog);
      commit("SET_TOTAL_PAGES",totalP);
      commit("SET_LOADING_STATE",false);
    })
    .catch((error) => {
      console.log(error);
      commit("SET_LOADING_STATE",false);
    });
}

export const createOrderEcommerce = ({commit}, order) => {
  axios
    .post("/apiecom/ecommerce/createOrderEcommerce", order)
    .then((response) => {
      commit("SET_ORDER", response.data.data);
    })
    .catch(() => {
    });
}

export const sendRegister = ({commit},form) => {
    axios
    .post("/apiecom/ecommerce/register", form)
    .then((response) => {
        if(response.data.success){
            commit("SET_SUCCESS_REGISTER", response.data.message);
        }else{
            commit("SET_ERROR_REGISTER", response.data.message);
        }
    })
    .catch((error) => {
      commit("SET_ERROR_REGISTER", error.response.data.message);
    });
}

export const sendLogin = ({commit},form) => {
    axios
    .post("/apiecom/ecommerce/login", form)
    .then((response) => {
        if(response.data.success){
            commit("SET_USER", response.data.user);
            commit("SET_SUCCESS_LOGIN", response.data.message);
        }else{
            commit("SET_ERROR_LOGIN", response.data.message);
        }
    })
    .catch((error) => {
      commit("SET_ERROR_LOGIN", error.response.data.message);
    });
}

export const getMercadoPagoUrl = ({commit},data) => {
  axios
  .post("/apiecom/ecommerce/mp/payment_url", data)
  .then((response) => {
    commit("SET_MP_URL", response.data);
  })
  .catch(() => {
  });
}

export const passwordRecovery = ({commit}, email) => {
  // let config = {
  //   headers: {
  //     'X-Requested-With': 'XMLHttpRequest'
  //   }
  // }
  axios
  .post("/apiecom/ecommerce/password/recovery", email)
  .then((response) => {
    commit("SET_PASSWORD_RECOVERY", response.data);
  })
  .catch((error) => {
    commit("SET_PASSWORD_RECOVERY", error.response.data);
  });
}

export const passwordReset = ({commit}, data) => {
  axios
  .post("/apiecom/ecommerce/password/reset", data)
  .then((response) => {
    commit("SET_PASSWORD_RECOVERY", response.data);
  })
  .catch((error) => {
    commit("SET_PASSWORD_RECOVERY", error.response.data);
  });
}
