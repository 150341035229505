<template>
  <div>
    <header class="header style7">
      <div class="container">
        <div class="main-header">
          <div class="row-header">
            <div class="col-lg-2 col-md-2  col-sm-2  header-element">
              <div class="logo" v-if="catalogView">
                <img :src="logo" alt="img" class="img-logo" />
              </div>
              <div class="logo" v-else>
                <router-link :to="{ name: 'Home' }">
                  <img :src="logo" alt="img" class="img-logo" />
                </router-link>
              </div>
            </div>
            <div class="col-lg-8 col-md-7 col-sm-6">
              <div class="block-search-block">
                <div class="form-search form-search-width-category">
                  <div class="form-content">
                    <div class="category">
                    </div>
                    <div class="inner">
                      <input
                        type="text"
                        class="input"
                        name="s"
                        v-model="q"
                        placeholder="Busqueda de producto"
                        v-on:keyup.enter="search"
                        v-on:keyup.delete="search"
                      />
                    </div>
                    <button class="btn-search" @click="search">
                      <i class="fas fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-4">
              <div class="header-control">
                <div
                  class="block-minicart tanajil-mini-cart block-header tanajil-dropdown"
                >
                  <MiniCart :onMobile="false" />
                  <MiniLogin :onMobile="false" />
                  <MiniMenu :onMobile="false" :showMenu=showMenu @close-menu="closeMenu" />
                  <a class="menu-bar mobile-navigation menu-toggle" href="#" @click="toggleMobileMenu">
                    <span></span>
                    <span></span>
                    <span></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="header-nav-container" v-if="!catalogView">
        <div class="container">
          <div class="header-nav-wapper main-menu-wapper">
            <div class="vertical-wapper block-nav-categori" v-losefocus="categoryMenuClose">
              <div class="block-title">
                <span class="icon-bar">
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
                <span class="text">Categorias</span>
              </div>
              <div class="block-content verticalmenu-content" id="category-filter">
                <ul class="tanajil-nav-vertical vertical-menu tanajil-clone-mobile-menu" id="cats">
                  <!--
                    <li class="menu-item">
                        <a @click="categoryClicked(null)" class="tanajil-menu-item-title" title="Todas"> Todas</a>
                    </li>
                    <SubCategory
                      :categories="categoryTree"
                      v-on:categoryClicked="categoryClicked($event)"
                    />
                  -->
                  <div class="show-all">
                    <a @click="categoryClicked(null)"><b>Mostrar todas las categorías</b></a>
                  </div>
                  <div class="dropdown-menu-container">
                    <CategoryList
                      :categories="categoryList"
                      v-on:categoryClicked="categoryClicked($event)"
                    />
                  </div>
                </ul>
              </div>
            </div>
            <div class="header-nav">
              <div class="container-wapper">
                <ul class="tanajil-clone-mobile-menu tanajil-nav main-menu" id="menu-main-menu">
                  <li class="menu-item ">
                    <router-link :to="{ name: 'Home' }">
                      <span v-on:click="home">Inicio</span>
                    </router-link>
                  </li>
                  <li class="menu-item ">
                    <router-link :to="{ name: 'Store' }">
                      <span v-on:click="openStore">Tienda</span>
                    </router-link>
                  </li>
                  <li class="menu-item ">
                    <router-link :to="{ name: 'Contact' }">
                        Contactanos
                    </router-link>
                  </li>
                  <li class="menu-item">
                    <router-link :to="{ name: 'About' }">
                      Nosotros
                    </router-link>
                  </li>
                </ul>
                <ul class="tanajil-clone-mobile-menu tanajil-nav main-menu social-menu" >
                    <li>
                        <a v-if="(networks !== undefined) ? (networks.facebook!=null) : false" :href="formatUrl((networks !== undefined) ? networks.facebook : '')" class="social-item facebook-icon" target="_blank">
                          <i class="icon fa fa-facebook icono"></i>
                        </a>
                    </li>
                    <li>
                        <a v-if="(networks !== undefined) ? (networks.twitter!=null) : false" :href="formatUrl((networks !== undefined) ? networks.twitter : '')" class="social-item twitter-icon" target="_blank">
                          <i class="icon fa fa-twitter icono"></i>
                        </a>
                    </li>
                    <li>
                        <a v-if="(networks !== undefined) ? (networks.instagram!=null) : false" :href="formatUrl((networks !== undefined) ? networks.instagram : '')" class="social-item instagram-icon" target="_blank">
                          <i class="icon fa fa-instagram icono"></i>
                        </a>
                    </li>
                    <li>
                        <a v-if="(networks !== undefined) ? (networks.whatsapp!=null) : false" :href="(networks !== undefined) ? ('https://wa.me/54' + networks.whatsapp) : ''" class="social-item whatsapp-icon" target="_blank">
                          <i class="icon fa fa-whatsapp icono"></i>
                        </a>
                    </li>
                    <li>
                        <a v-if="(networks !== undefined) ? (networks.telegram!=null) : false" :href="(networks !== undefined) ? networks.telegram : ''" class="social-item telegram-icon" target="_blank">
                          <i class="icon fa fa-telegram icono"></i>
                        </a>
                    </li>
                </ul>
              </div>
                
            </div>
          </div>
        </div>
      </div>
    </header>
    <!-- Mobile Header -->
    <div class="header-device-mobile">
      <div class="wapper">
        <div class="item mobile-logo">
          <div class="logo">
            <img :src="`${logo}`" alt="img" class="img-logo-mobile" v-if="catalogView" />
            <router-link :to="{ name: 'Home' }" v-else >
              <img :src="`${logo}`" alt="img" class="img-logo-mobile" />
            </router-link>
          </div>
        </div>

        <div class="item item mobile-search-box has-sub">
          <a href="#">
            <span class="icon">
              <i class="fa fa-search" aria-hidden="true"></i>
            </span>
          </a>
          <div class="block-sub">
            <a href="#" class="close">
              <i class="fa fa-times" aria-hidden="true"></i>
            </a>
            <div class="header-searchform-box">
              <form class="header-searchform">
                <div class="searchform-wrap">
                  <input
                    type="text"
                    v-model="q"
                    class="search-input"
                    placeholder="Ingrese palabras clave..."
                  />
                  <router-link :to="{ name: 'Store' }">
                    <input
                      type="submit"
                      class="submit button"
                      value="Buscar"
                      v-on:click="search"
                    />
                  </router-link>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="item menu-bar" v-if="!catalogView">
          <MiniMenu :onMobile="true" :showMenu=showMenu @close-menu="closeMenu"/>
          <a class=" mobile-navigation menu-toggle" href="#" @click="toggleMobileMenu">
            <span></span>
            <span></span>
            <span></span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MiniCart from "./MiniCart.vue";
import MiniLogin from "./MiniLogin.vue";
import MiniMenu from "./MiniMenu.vue";
// import SubCategory from "./SubCategory.vue";
import CategoryList from "./CategoryList.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "TheHeader",
  components: {
    MiniCart,
    MiniLogin,
    MiniMenu,
    CategoryList
  },
  watch: {
    imgSrcs(newImageSrc){
      if (newImageSrc !== undefined && newImageSrc.logo !== undefined) {
        this.logo = newImageSrc.logo;
      }
    },
    categoryTree: function () {
      this.treeToMenu()
    }
  },
  computed: {
    ...mapGetters({
      categoryTree: "ecommerce/categoryTree",
      imgSrcs: "ecommerce/imgSrcs",
      searchdata: "ecommerce/searchdata",
      categoryList: "ecommerce/categoryList",
      networks: "ecommerce/networks"
    }),
    catalogView:{
      get(){
        return this.$route.name == 'Catalog'
      }
    },
    catMen:{
      get(){
        return 'owowowow'
      }
    }
  },
  methods: {
    ...mapActions({
      loadProducts: 'ecommerce/loadProducts',
      loadCatalog: 'ecommerce/loadCatalog'
    }),
    ...mapMutations({
      setQuery: 'ecommerce/SET_QUERY',
      setFilterCategories: 'ecommerce/SET_FILTER_CATEGORIES'
    }),
    getByCategory(id) {
      this.setFilterCategories([id]);
      this.loadProducts();
    },
    toggleMobileMenu() {
      if (this.showMenu === false){
        this.showMenu = true
      }else{
        this.showMenu = false
      }
    },
    closeMenu(){
      this.showMenu = false
    },
    search() {
      if (this.$route.name =='Catalog'){
        this.setQuery(this.q);
        this.$store.dispatch("ecommerce/loadCatalog", this.outData);
      }else{
        this.setQuery(this.q);
        this.loadProducts();
        if(this.$route.name !== 'Store') {
          let newPath = `/${this.$route.params.company}/store`;
          if (this.$route.path !== newPath) {
            this.$router.push({ path: newPath });
          }
        }
      }
    },
    
    loadAll(){
      this.loadProducts();
    },
    toggleSearch() {
      document.getElementById("mobile-search-box").classList.remove("open");
    },
    categoryClicked(category){
      this.categoryMenuClose();
      if(category === null){
        this.setFilterCategories([]);
        this.loadProducts();
        if(this.$route.name !== 'Store') {
          let newPath = `/${this.$route.params.company}/store`;
          if (this.$route.path !== newPath) {
            this.$router.push({ path: newPath });
          }
        }  
      }
      else {
        this.getByCategory(category.id)
        if(this.$route.name !== 'Store') {
          let newPath = `/${this.$route.params.company}/store`;
          if (this.$route.path !== newPath) {
            this.$router.push({ path: newPath });
          }
        }
      }
    },
    categoryMenuClose(){
        document.getElementById("category-filter").classList.remove("show-up");
    },
    openStore(){
      this.loadAll();
    },
    home(){
      this.setFilterCategories([]);
      this.loadProducts();
    },
    treeToMenu () {
      this.categoryMenu = this.categoryTree

      // const tempMenu = this.categoryMenu.map(item => {
      //   const container = {};

      //   container[item.id] = item.id;
      //   container[item.name] = item.name;

      //   return container;
      // })
      /* Tenés que gettear categoryTree y hacer un array de objetos (categoryMenu) con los campos id, name y parent */
    },
    formatUrl(url){
      if(url.startsWith('http')){
        return url;
      }else{
        return 'http://' + url;
      }
    }
  },
  data() {
    return {
      q:'',
      logo: "/assets/images/ph-logo.png",
      showMenu: false,
      categoryMenu: [],
      outData:{
        uid: this.$route.params.uid,
        catID:null,
      },
    };
  },

  created (){
    if( this.imgSrcs !== undefined) {
        if(this.imgSrcs.logo !== undefined){
            this.logo = this.imgSrcs.logo;
        }
    }
    if(this.searchdata.q!=null){
        this.q = this.searchdata.q;
    }
  },
  directives: {
    losefocus: {
        bind: function (el, binding, vnode) {
            el.clickOutsideEvent = function (event) {
                if (!(el == event.target || el.contains(event.target))) {
                    vnode.context[binding.expression](event);
                  }
              };
            document.body.addEventListener('click', el.clickOutsideEvent)
          },
        unbind: function (el) {
            document.body.removeEventListener('click', el.clickOutsideEvent)
          },
      }
  }
};
</script>

<style scoped>
.searchform-wrap {
  display: flex;
  padding-bottom: 2em;
}
.main-header {
  padding: 15px 0;
}
.img-logo {
  max-height: 60px;
}

.header-device-mobile  {
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.4);
}
#category-filter {
  width: 1200px;
  max-height: 500px;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.4);
  border-top: 1px solid #fff;
  padding: 0;
  overflow-y: auto;
}
.show-all {
  max-width: 1200px;
  height: 50px;
  background-color: var(--primary-color);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #fff;
  font-size: 18px;
}
.show-all a {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.show-all a:hover {
  cursor: pointer;
  color: #aaa;
}

.container-wapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-nav .social-menu li a {
  color: #fff;
  background-color: transparent;
  border-radius: 50%;
  margin: 5px;
  font-size: 18px;
  height: 40px;
  min-width: 40px;
  line-height: 42px;
  text-align: center;
  display: inline-block;
  position: relative;
  z-index: 1;
  padding: 0;
}
.header-nav .social-menu li a::before {
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scale(0.3);
  -moz-transform: scale(0.3);
  -ms-transform: scale(0.3);
  -o-transform: scale(0.3);
  transform: scale(0.3);
  transition: 0.8s;
  border-radius: 50%;
}
.header-nav .social-menu li a:hover::before {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.header-nav .social-menu li a i {
  z-index: 1;
  position: relative;
  padding-left: 1px;
}

.header-nav .social-menu li .facebook-icon::before {
  background-color: #1877F2; 
}
.header-nav .social-menu li .instagram-icon::before {
  background-image: linear-gradient(135deg, #F58529, #DD2A7B);
}
.header-nav .social-menu li .whatsapp-icon::before {
  background-color: #25D366; 
}
.header-nav .social-menu li .twitter-icon::before {
  background-color: #1DA1F2; 
}
.header-nav .social-menu li .telegram-icon::before {
  background-color: #0088cc; 
}

</style>
