<template>
  <transition name="modal">
    <div class="modal-mask" v-if="alertWelcome && allowedRoute">
        <div class="modal-wrapper">
            <div class="modal-container">

                <div class="modal-header">
                    <slot name="header">
                        ¡ BIENVENIDOS !
                        <a class="modal-close-button" v-on:click="closeModal()" style="cursor:pointer;">X</a>
                    </slot>
                </div>

                <div class="modal-body">
                    <slot name="body">
                        {{alerts.welcome.message}}
                    </slot>
                </div>
            </div>
        </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  props: ['message'],
  computed: {
    ...mapGetters({
      loading: 'ecommerce/loading',
      alerts: 'ecommerce/alerts'
    }),
    alertWelcome:{
        get: function () {
            if(this.loading==false && this.alerts!=null){
                if(this.alerts.welcome !== undefined){
                    return this.alerts.welcome.active;
                }
            }
            return false;
        }
    },
    allowedRoute() {
        if(this.$route.name=='Home'){
            return true;
        }
        return false;
    }
  },
  methods: {
    ...mapMutations({
      closeAlertWelcome: 'ecommerce/CLOSE_ALERT_WELCOME'
    }),
    closeModal() {
      this.closeAlertWelcome();
    },
  }
};
</script>

<style>

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 50vw;
  margin: 0px auto;
  padding: 10px 20px;
  background-color: var(--accent-color);
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header{
  margin-top: 0;
  color: white;
  font-weight: bold;
  font-size: 16px;
}

.modal-body {
  margin: 10px 0;
  color: black;
  font-size: 14px;
}

.modal-close-button {
  float: right;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

</style>
<style scoped>
@media (max-width: 600px) {
  .modal-container {
    width: 80vw;
  }
}
</style>
