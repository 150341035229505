<template>
  <div>
    <div class="tanajil-menu-clone-wrap" :class="{'open': showMenu}">
      <div class="tanajil-menu-panels-actions-wrap">
        <span class="tanajil-menu-current-panel-title">MENU</span><a class="tanajil-menu-close-btn tanajil-menu-close-panels" href="#" @click="closeMenu()">x</a>
      </div>
      <div class="menu-container">
        <ul class="mobile-links mobile-links--level--0 mobile-links__item--open">
          <li class="mobile-links__item">
            <div class="mobile-links__item-title">
              <p class="mobile-links__item-link" @click="goTo('Home')">Inicio</p>
            </div>
          </li>
          <li class="mobile-links__item">
            <div class="mobile-links__item-title">
              <p class="mobile-links__item-link" @click="goTo('Store')">Tienda</p>
            </div>
          </li>
          <li class="mobile-links__item">
            <div class="mobile-links__item-title">
              <p class="mobile-links__item-link" @click="goTo('Contact')">Contactanos</p>
            </div>
          </li>
          <li class="mobile-links__item">
            <div class="mobile-links__item-title">
              <p class="mobile-links__item-link" @click="goTo('About')">Nosotros</p>
            </div>
          </li>
          <li class="mobile-links__item" data-collapse-item @click="toggleSubMenu($event)">
            <div class="mobile-links__item-title">
              <p class="mobile-links__item-link">Categorías </p>
              <button class="mobile-links__item-toggle" type="button" data-collapse-trigger @click="toggleSubMenu($event)">
                <svg class="mobile-links__item-arrow" width="12px" height="7px">
                  <use xlink:href="../../public/assets/images/sprite.svg#arrow-rounded-down-12x7"></use>
                </svg>
              </button>
            </div>
            <div class="mobile-links__item-sub-links" data-collapse-content>
              <ul class="mobile-links mobile-links--level--1">
                <li v-for="category in categoryTree" :key="category.id" class="mobile-links__item" data-collapse-item>
                  <div class="mobile-links__item-title">
                    <p class="mobile-links__item-link" @click="categoryClicked(category)" >{{category.name}}</p>
                    <button v-if="Array.isArray(category.children) && category.children.length > 0" class="mobile-links__item-toggle" type="button" data-collapse-trigger @click="toggleSubMenu($event)">
                      <svg class="mobile-links__item-arrow" width="12px" height="7px">
                        <use xlink:href="../../public/assets/images/sprite.svg#arrow-rounded-down-12x7"></use>
                      </svg>
                    </button>
                  </div>
                  <div v-if="Array.isArray(category.children) && category.children.length > 0" class="mobile-links__item-sub-links" :ref="'subLinks' + category.id" data-collapse-content>
                    <ul class="mobile-links mobile-links--level--2">
                      <li v-for="subcategory in category.children" :key="subcategory.id" class="mobile-links__item" data-collapse-item>
                        <div class="mobile-links__item-title">
                          <p class="mobile-links__item-link" @click="categoryClicked(subcategory)">{{subcategory.name}}</p>
                          <button v-if="Array.isArray(subcategory.children) && subcategory.children.length > 0" class="mobile-links__item-toggle" type="button" data-collapse-trigger @click="toggleSubMenu($event)">
                            <svg class="mobile-links__item-arrow" width="12px" height="7px">
                              <use xlink:href="../../public/assets/images/sprite.svg#arrow-rounded-down-12x7"></use>
                            </svg>
                          </button>
                        </div>
                        <div v-if="Array.isArray(subcategory.children) && subcategory.children.length > 0" class="mobile-links__item-sub-links" :ref="'subLinks' + subcategory.id" data-collapse-content>
                          <ul class="mobile-links mobile-links--level--3">
                            <li v-for="subsub in subcategory.children" :key="subsub.id" class="mobile-links__item" data-collapse-item>
                              <div class="mobile-links__item-title">
                                <p class="mobile-links__item-link" @click="categoryClicked(subsub)">{{subsub.name}}</p>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </li>
        </ul>
        <div class="mobile-social-menu">
          <ul class="tanajil-clone-mobile-menu tanajil-nav main-menu social-menu" >
            <li v-if="(networks !== undefined) ? (networks.facebook!=null) : false">
                <a :href="formatUrl((networks !== undefined) ? networks.facebook : '')" class="social-item facebook-icon" target="_blank">
                  <i class="icon fa fa-facebook icono"></i>
                </a>
            </li>
            <li v-if="(networks !== undefined) ? (networks.twitter!=null) : false">
                <a :href="formatUrl((networks !== undefined) ? networks.twitter : '')" class="social-item twitter-icon" target="_blank">
                  <i class="icon fa fa-twitter icono"></i>
                </a>
            </li>
            <li v-if="(networks !== undefined) ? (networks.instagram!=null) : false">
                <a :href="formatUrl((networks !== undefined) ? networks.instagram : '')" class="social-item instagram-icon" target="_blank">
                  <i class="icon fa fa-instagram icono"></i>
                </a>
            </li>
            <li v-if="(networks !== undefined) ? (networks.whatsapp!=null) : false">
                <a :href="(networks !== undefined) ? ('https://wa.me/54' + networks.whatsapp) : ''" class="social-item whatsapp-icon" target="_blank">
                  <i class="icon fa fa-whatsapp icono"></i>
                </a>
            </li>
            <li v-if="(networks !== undefined) ? (networks.telegram!=null) : false">
                <a :href="(networks !== undefined) ? networks.telegram : ''" class="social-item telegram-icon" target="_blank">
                  <i class="icon fa fa-telegram icono"></i>
                </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  props: ['showMenu'],
  computed: {
    ...mapGetters({
      categoryTree: "ecommerce/categoryTree",
      networks: "ecommerce/networks"
    }),
    catalogView:{
      get(){
        return this.$route.name == 'Catalog'
      }
    }
  },
  methods: {
    ...mapActions({
      loadProducts: 'ecommerce/loadProducts'
    }),
    ...mapMutations({
      setFilterCategories: 'ecommerce/SET_FILTER_CATEGORIES'
    }),
    formatUrl(url){
      if(url.startsWith('http')){
        return url;
      }else{
        return 'http://' + url;
      }
    },
    goTo(url){
      if(this.$route.name !== url) {
          this.$router.push({ name: url , params: { company: this.$route.params.company }});
        }
      this.closeMenu()
    },
    closeMenu() {
      this.$emit('close-menu')
    },
    toggleSubMenu(event) {
      const button = event.target;
      const item = button.closest('[data-collapse-item]')
      const content = item ? item.querySelector('[data-collapse-content]') : null

      if (item.classList.contains('mobile-links__item--open')) {
        item.classList.remove('mobile-links__item--open')
        if (content) {
        content.style.height = '0'
        }

      } else {
        item.classList.add('mobile-links__item--open')
        if (content) {
        content.style.height = content.scrollHeight + 'px'
        }

      }
      const transitionEndHandler = () => {
        if (content) {
          content.style.height = ''
          content.removeEventListener('transitionend', transitionEndHandler)
        }
      };
      if (content) {
      content.addEventListener('transitionend', transitionEndHandler)
      }

      event.stopPropagation()
    },
    getByCategory(id) {
      this.setFilterCategories([id]);
      this.loadProducts();
    },
    categoryClicked(category){
      if(category === null){
        this.setFilterCategories([]);
        this.loadProducts();
        if(this.$route.name !== 'Store') {
          this.$router.push({ name: "Store" , params: { company: this.$route.params.company }});
        }
      }
      else {
        this.getByCategory(category.id)
        if(this.$route.name !== 'Store') {
          this.$router.push({ name: "Store" , params: { company: this.$route.params.company }});
        }
      }
      this.closeMenu()
    }
  }
}
</script>

<style scoped>
.tanajil-menu-clone-wrap {
  border-right: 1px solid #eee;
}
.menu-container {
  height: calc(100vh - 60px);
  overflow-y: auto;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .menu-container {
    
    padding-bottom: 60px;
  }
}
.mobile-links {
  list-style: none;
  padding: 0;
  margin: 0;
}
.mobile-links__item-title p
{
  margin: 0!important;
}
.mobile-links__item {
  border-bottom: 1px solid #ebebeb;
}
.mobile-links__item-title {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: stretch;
  align-items: stretch;
  cursor: pointer;
}
.mobile-links__item-link {
  -ms-flex-positive: 1;
  flex-grow: 1;
  color: #3d464d;
}
.mobile-links__item-link,
.mobile-links__item-link:hover {
  color: inherit;
}
.mobile-links__item-toggle {
  border: none;
  border-left: 1px solid #ebebeb;
  background: transparent;
  padding: 0;
  width: 54px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  cursor: inherit;
  fill: #999;
  transition: fill 0.2s;
}
.mobile-links__item-toggle:focus {
  outline: none;
}
.mobile-links__item-toggle:focus,
.mobile-links__item-toggle:hover {
  cursor: inherit;
}
.mobile-links__item-arrow {
  transition: transform 0.3s;
}
.mobile-links__item-sub-links {
  visibility: hidden;
  overflow: hidden;
  height: 0;
  opacity: 0;
  transition: height 0.3s, opacity 0.3s, visibility 0s 0.3s;
}
.mobile-links__item--open > .mobile-links__item-sub-links {
  visibility: visible;
  height: auto;
  opacity: 1;
  transition-delay: 0s, 0s, 0s;
}
.mobile-links__item--open
  > .mobile-links__item-title
  .mobile-links__item-arrow {
  transform: rotate(180deg) translateY(1px);
}
.mobile-links--level--0 > .mobile-links__item {
  border-bottom: 1px solid #ebebeb;
}
.mobile-links--level--0 > .mobile-links__item > .mobile-links__item-title {
  height: 48px;
}
.mobile-links--level--0
  > .mobile-links__item
  > .mobile-links__item-title
  .mobile-links__item-link {
  padding: 12px 20px;
}
.mobile-links:not(.mobile-links--level--0) {
  border-top: 1px solid #ebebeb;
}
.mobile-links--level--1 {
  background: #f7f7f7;
  font-size: 14px;
  line-height: 14px;
}
.mobile-links--level--1 .mobile-links__item .mobile-links__item-link {
  padding: 13px 20px;
}
.mobile-links--level--1 .mobile-links {
  padding-left: 20px;
  background: #fff;
}
.mobile-links--level--1 .mobile-links > li {
  background: #f7f7f7;
  border-left: 1px solid #ebebeb;
}
.mobile-links--level--1 .mobile-links__item:last-child {
  border-bottom: none;
}
.mobile-links--level--3 {
  background: #f9f9f9;
  font-size: 13px;
  line-height: 13px;
}

.mobile-links--level--3 .mobile-links__item .mobile-links__item-link {
  padding: 12px 20px;
}

.mobile-links--level--3 .mobile-links {
  padding-left: 20px;
  background: #fff;
}

.mobile-links--level--3 .mobile-links > li {
  background: #f9f9f9;
  border-left: 1px solid #ebebeb;
}

.mobile-links--level--3 .mobile-links__item:last-child {
  border-bottom: none;
}
.menu-container .mobile-social-menu {
  display: block;
  position: relative;
}
.mobile-social-menu {
  padding-top: 10px;
}
.social-menu {
  display: flex;
  justify-content: space-around;
}
.mobile-social-menu .social-menu li a {
  color: #fff;
  background-color: transparent;
  border-radius: 50%;
  margin: 5px;
  font-size: 18px;
  height: 40px;
  min-width: 40px;
  line-height: 42px;
  text-align: center;
  display: inline-block;
  position: relative;
  padding: 0;
}
.mobile-social-menu .social-menu li a::before {
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  opacity: 0;
  opacity: 1;
  visibility: visible;
  border-radius: 50%;
}

.mobile-social-menu .social-menu li a i {
  position: relative;
  padding-left: 1px;
}

.mobile-social-menu .social-menu li .facebook-icon::before {
  background-color: #1877F2; 
}
.mobile-social-menu .social-menu li .instagram-icon::before {
  background-image: linear-gradient(135deg, #F58529, #DD2A7B);
}
.mobile-social-menu .social-menu li .whatsapp-icon::before {
  background-color: #25D366; 
}
.mobile-social-menu .social-menu li .twitter-icon::before {
  background-color: #1DA1F2; 
}
.mobile-social-menu .social-menu li .telegram-icon::before {
  background-color: #0088cc; 
}
</style>
