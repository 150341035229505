
<template>
  <div class="dropdown-menu-container" 
    :class="{
      'container-1-column' : (categories.length <= 10),
      'container-2-columns': ((categories.length > 10) && (categories.length <= 20)),
      'container-3-columns': ((categories.length > 20) && (categories.length <= 50)) 
    }"
  >
    <div v-for="category in categoriesSorted" :key="category.id">
      <div :class="{ 'last-child-category': isLastChild(category.id) }">
        <a @click="categoryClicked(category)" :class="{ 'parent-category': !hasParent(category.pos) }">{{ category.name }}</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CategoryList",

  props: ["categories", "categoryList"],

  methods: {
    categoryClicked(category) {
      this.$emit("categoryClicked", category);
    },
    hasChildren(obj){
      console.log(obj);
      // return obj.hasOwnProperty('children');
    },
    hasParent(pos) {
      return (pos !== 0)
    },
    isLastChild(id) {
      let idArray = []
      for (let i = 0; i < this.categoriesSorted.length; i++) {
        if (typeof this.categoriesSorted[i + 1] !== 'undefined' && this.categoriesSorted[i + 1].pos === 0) { 
          idArray.push(this.categoriesSorted[i].id)
        }
      }
      return idArray.includes(id)
    }
  },
  computed: {
    categoriesSorted: function() {
      var categs = this.categories
      var categsSorted = []
      var siblings = []
      for (let i = 0; i < categs.length; i++) {
        if (categs[i].pos === 0) {
          categsSorted.push(categs[i])
        } else {
          siblings.push(categs[i])
          if (typeof categs[i + 1] === 'undefined' || categs[i + 1].pos === 0) {
            siblings.sort((a,b)=> (a.name > b.name ? 1 : -1))
            categsSorted.push(...siblings)
            siblings = []
          }
        }
      }
      return categsSorted
    }
  }
};
</script>

<style scoped>
.dropdown-menu-container {
  padding: 10px;
  max-width: 1200px;
  box-sizing: border-box;
  background-color: var(--primary-color);
  color: #fff;
  column-count: 5;
  column-gap: 15px;
  height: auto;
  font-size: 16px;
}
ul {
    list-style-type: none;
}
.parent-category {
  font-weight: 700;
  text-transform: uppercase;
}
.last-child-category {
  margin-bottom: 10px;
}
a:hover {
  cursor: pointer;
  color: #aaa;
}
.container-1-column {
  column-count: 1;
}
.container-2-columns {
  column-count: 2;
}
.container-3-columns {
  column-count: 3;
}
</style>