import Main from "../views/Main.vue"
import store from '../store/store.js'

const routes = [
  {
    path: "/:company",
    component: Main,
    children: [
        {
          path: "",
          name: "Home",
          component: () =>
            import(/* webpackChunkName: "home" */ "../views/Home.vue"),
        },
        {
          path: "store",
          name: "Store",
          component: () =>
            import(/* webpackChunkName: "store" */ "../views/Store.vue"),
        },
        {
          path: "about",
          name: "About",
          component: () =>
            import(/* webpackChunkName: "about" */ "../views/About.vue"),
        },
        {
          path: "contact",
          name: "Contact",
          component: () =>
            import(/* webpackChunkName: "contact" */ "../views/Contact.vue"),
        },
        {
          path: "arma-tu-pc",
          name: "PC Builder",
          component: () =>
            import(/* webpackChunkName: "arma-tu-pc" */ "../views/PCBuilder.vue"),
        },
        { 
          path: "arma-tu-pc/intel",
          name: "PC Builder Intel",
          component: () =>
            import(/* webpackChunkName: "arma-tu-pc" */ "../views/PCBuilderIntel.vue"),
        },
        { 
          path: "arma-tu-pc/amd",
          name: "PC Builder AMD",
          component: () =>
            import(/* webpackChunkName: "arma-tu-pc" */ "../views/PCBuilderAMD.vue"),
        },
        {
          path: "productDetails/:id",
          name: "ProductDetails",
          component: () =>
            import(
              /* webpackChunkName: "ProductDetails" */ "../views/FullWidthProduct.vue"
            ),
        },
        {
          path: "checkoutFlow",
          name: "CheckoutFlow",
          component: () =>
            import("../views/CheckoutFlow.vue"),
            beforeEnter: (to, from, next) => {
              const basketSesion = JSON.parse(window.sessionStorage.getItem("vuex")).ecommerce.basket.length
              const basketGetter = store.getters["ecommerce/basketCount"]
              if (basketSesion > 0 || basketGetter > 0) {
                next();
              } else {
                next({name: 'Home', params: { company: to.params.company }});
              }
            }
        },
        {
          path: "completeOrder",
          name: "CompleteOrder",
          component: () =>
            import("../views/CompleteOrder.vue"),
            beforeEnter: (to, from, next) => {
              const basketSesion = JSON.parse(window.sessionStorage.getItem("vuex")).ecommerce.basket.length
              const basketGetter = store.getters["ecommerce/basketCount"]
              if (basketSesion > 0 || basketGetter > 0) {
                next();
              } else {
                next({name: 'Home', params: { company: to.params.company }});
              }
            }
        },
        {
          path: "catalog/:uid",
          name: "Catalog",
          component: () =>
            import(
              /* webpackChunkName: "Catalog" */ "../views/Catalog.vue"
            ),
        }
    ]
  },
  {
    path: "/notfound",
    name: "NotFound",
    component: () =>
      import(/* webpackChunkName: "NotFound" */ "../views/NotFound.vue"),
  },
];

export default routes;
