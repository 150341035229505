import axios from 'axios';

export default function execute() {
    axios.interceptors.request.use(config => {
        config.baseURL = process.env.VUE_APP_BASE_URL,
        config.headers['X-Referrer'] = window.location.href;
        return config;
    }, error => {
        // Do something with request error
        console.error(error); // for debug
        Promise.reject(error);
    })
    axios.interceptors.response.use(function (response) {
        return response;
    }, function (error) {
        // if (error.response.status == 503) {
        //     window.location.href = '/notfound';
        // }
        return Promise.reject(error);
    });
}