<template>
  <transition name="slide">
    <div class="row-header slidedown" v-if="alertNews && allowedRoute">
        <div class="col-md-1">
            <a><i class="fas fa-exclamation-triangle"/></a>
        </div>
        <div class="col-md-10 message-body">
            <span>{{alerts.news.message}}</span>
        </div>
        <div class="col-md-1">
            <a class="closebtn" v-on:click="closeModal()" style="cursor:pointer;">X</a>
        </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
    props: ['message'],
    computed: {
      ...mapGetters({
        loading: 'ecommerce/loading',
        alerts: 'ecommerce/alerts'
      }),
      alertNews:{
          get: function () {
              if(this.loading==false && this.alerts!=null){
                  if(this.alerts.news !== undefined){
                      return this.alerts.news.active;
                  }
              }
              return false;
          }
      },
      allowedRoute() {
          if(this.$route.name=='Home'){
              return true;
          }
          return false;
      }
    },
    methods: {
      ...mapMutations({
        closeAlertNews: 'ecommerce/CLOSE_ALERT_NEWS'
      }),
      closeModal() {
        this.closeAlertNews();
      },
    },
};
</script>

<style>

.slidedown {
  width: 100% !important;
  top: 0px;
  background: var(--primary-color);
  border: none;
  padding: 5px;
  height: auto;
  box-shadow: 1px 1px 10px rgba(0,0,0,.5);

  font-weight: 500;
  color: #ffffff;
  text-align: center;

  position: fixed;
  z-index: 9999;
}

.slidedown a{
  font-size: 20px;
  padding-left: 15px;
  padding-right: 15px;
}

.slidedown span{
  font-size: 14px;
}

.slide-enter-active,
.slide-leave-enter {
  transform: translateY(0);
  transition: all .2s linear;
}
.slide-enter,
.slide-leave-to {
  transform: translateY(-100%);
  transition: all .2s linear;
}
</style>
<style scoped>
.message-body {
  max-height: 80vh;
  overflow-y: auto;
}
</style>
