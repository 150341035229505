import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/store";

import $ from "jquery";
window.$ = window.jQuery = $;

import VueTreeNavigation from 'vue-tree-navigation';
Vue.use(VueTreeNavigation);

import interceptor from './helpers/interceptor.js';
interceptor();

Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
