<template>
  <div class="shopcart-description content-wrap">
    <router-link :to="{ name: 'ProductDetails', params: { id:item.product.id.toString() } }" class="product-media">
      <img :src="(item.product.images !== undefined && item.product.images.length !== 0)?`${item.product.images[0]}`:'/assets/images/ph-product.png'" alt="img" />
    </router-link>
    <div class="product-details">

      <h5 class="product-name">
        <router-link :to="{ name: 'ProductDetails', params: { id:item.product.id.toString() } }">{{
          item.product.name
        }}</router-link>
      </h5>

      <div class="variations">
        <div v-for="attribute in this.item.variant.attributes" :key="attribute.id">
          <!-- <div v-if="attribute.class=='color'" class="list-item list-color">
            <span class="attribute_color">
              <a class="color-box" :style="getHexa(attribute.data)"></a>
            </span>
          </div> -->
          <div class="list-item">
            <span> {{ attribute.name + '&#160;'}} </span>
          </div>
        </div>
      </div>
      <div>
        <span class="product-price">
        <span class="price">
          Precio: <span>$ {{ item.product.prices[0] }}</span>
        </span>
      </span>
      <span class="product-quantity"> (x {{ itemAmount }}) </span>
      </div>
      <div>
        <span class="product-price">
          <span class="price">
            Subtotal: <span>$ {{ subtotal }}</span>
          </span>
        </span>
      </div>
    </div>
    <div v-show="withaddbuttons" class="quantity-add-to-cart">
      <div class="quantity">
        <div class="control">
          <a class="btn-number " href="#" @click.prevent="updateBasketProductAmount(item.amount-1)">
              -
          </a>
          <input type="number" v-model.number="itemAmount" title="cantidad" class="input-qty qty"
            min="0"
            size="4"
            @input="updateValue"
          />
          <a href="#" class="btn-number " @click.prevent="updateBasketProductAmount(item.amount+1)">
              +
          </a>
        </div>
      </div>
      <div v-show="withaddbuttons" class="product-remove">
        <a @click="removeFromBasketFunction"
          ><i class="fa fa-trash-o" aria-hidden="true"></i
        ></a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    withaddbuttons: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters({
      configuration: "ecommerce/configuration",
      attributes: "ecommerce/attributes"
    }),
    subtotal() {
      return (this.item.product.prices[0] !== undefined) ? this.item.product.prices[0] * this.item.amount : 0;
    },
    itemAmount: {
      get: function () {
        return this.item.amount;
      },
      set: function (value) {
        if (!Number.isNaN(value) && value>0) {
            if (this.configuration.sale_without_stock) {
              this.item.amount = value;
            } else {
                if (Object.entries(this.item.variant).length===0) {
                  if (value<=this.item.product.stock) {
                    this.item.amount = value;
                  }
                } else {
                  if (value<=this.item.variant.stock) {
                    this.item.amount = value;
                  }
                }
            }
        }
      }
    },
  },
  methods: {
    ...mapMutations({
      updateBasketAmount: 'ecommerce/UPDATE_BASKET_AMOUNT',
      removeFromBasket: 'ecommerce/REMOVE_FROM_BASKET'
    }),
    updateBasketProductAmount(q) {
      this.itemAmount = q;
      this.updateBasketAmount({ amount: this.itemAmount, product:this.item.product});
    },
    removeFromBasketFunction() {
      this.removeFromBasket(this.item);
    },
    getHexa(field) {
      let data = JSON.parse(field);
      let style = 'background-color: ' + data.code + ';border: 1px solid #eeeeee;';
      return style;
    },
    updateValue(event) {
      const value = event.target.value
      if (String(value).length <= 4) {
        this.itemAmount = value
      } else {
        this.itemAmount = parseInt(String(value).substring(0,4))
      }
    }
  }
};
</script>

<style scoped>
.control{
  width: 60px !important;
  padding: 0 5px !important;
  height: 20px;
}

.quantity,
.product-remove {
  margin: 0.5em;
}
.product-remove:hover {
  cursor: pointer;
}
.product-remove i{
  padding: 10px;
  transition: 0.3s;
}
.product-remove:hover i{
  color: red;
}
.color-box {
  height: 10px;
  width: 10px;
  border-radius: 5px;
  margin-left: 5px;
  border: 1px solid grey;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  appearance: textfield;
  -moz-appearance: textfield;
}
.input-qty {
  padding: 0;
}
.quantity-add-to-cart{
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
</style>
