
export const products = (state) => state.products
export const product = (state) => state.product
export const featured = (state) =>state.featured
export const basket = (state) => state.basket
export const basketCount = (state) => state.basket.length
export const basketSum = (state) => state.basket.reduce((sum, item) => (sum + item.amount * item.product.prices[item.pricelist]),0.0)
export const categoryTree = (state) => state.categories
export const categoryList = (state) => state.categoryList
export const brands = (state) => state.brands
export const configuration = (state) => state.configuration
export const searchdata = (state) => state.searchdata
export const attributes = (state) => state.attributes
export const contact = (state) => state.configuration.contact
export const networks = (state) => state.configuration.networks
export const customStyles = (state) => state.configuration.styles
export const totalPages = (state) => state.searchdata.pagination.totalPages
export const order = (state) => state.order
export const errors = (state) => state.errors
export const successes = (state) => state.successes
export const loading = (state) => state.loading
export const mp_url = (state) => state.mp_ur
export const passwordRecoveryResponse = (state) => state.passwordRecoveryResponse
export const user = (state) => {
    if (Object.entries(state.user).length===0) {
        return null;
    } else {
        return state.user;
    }
}
export const client = (state) => {
    if (Object.entries(state.user).length>0) {
        if (Object.entries(state.user.client).length>0) {
            return state.user.client;
        }
    }
    return null
}
export const imgSrcs = (state) => {
    if (state.configuration.images !== undefined) {
        return state.configuration.images;
    }
    return []
}
export const captchakey = (state) => {
    if (state.configuration.sitekey !== undefined) {
        return state.configuration.sitekey;
    }
    return null
}
export const alerts = (state) => {
    if (state.alerts !== undefined) {
        return state.alerts;
    }
    return null
}
export const catalog = (state) => {
    if (state.catalog!==undefined) {
        return state.catalog;
    }
    return null
}
export const attributeFilters = (state) => state.attributes.filter(e => e.filter == 1)
