import Vue from "vue"
import Vuex from "vuex"
import VuexPersistence from 'vuex-persist'


Vue.use(Vuex);

import ecommerce from './ecommerce'
const vuexPersist = new VuexPersistence({
    storage: window.sessionStorage,
    reducer: (state) => ({
      ecommerce: {
        configuration: state.ecommerce.configuration,
        user: state.ecommerce.user,
        basket: state.ecommerce.basket,
        order: state.ecommerce.order,
        category: state.ecommerce.searchdata.filters.category,
        brand: state.ecommerce.searchdata.filters.brand,
        attList: state.ecommerce.searchdata.filters.attList,
        pageNumber: state.ecommerce.searchdata.pagination.pageNumber,
        totalPages: state.ecommerce.searchdata.pagination.totalPages,
        itemCount: state.ecommerce.searchdata.pagination.itemCount,
        sortMethod: state.ecommerce.searchdata.pagination.sortMethod
      }
    })
})

export default new Vuex.Store({
  plugins: [vuexPersist.plugin],
  modules: {
    ecommerce
  },
  strict: true
});
