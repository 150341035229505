<template>
  <div>
    <AlertOverlay/>
    <AlertSlideDown/>
    <Loading 
        :active="loading"
        :can-cancel="false"
        :is-full-page="true"
        :lock-scroll="true"
        :color="(customStyles !== undefined) ? customStyles.colorPrimary :'#0d6efd'"
        :height="100"
        :width="100"
        :loader="'dots'"
    />

    <TheHeader class="header" />
    <router-view :class="(catalogView)?'router-view-catalog':'router-view-store'" />
    <TheFooter />
    <WhatsappWidget />
    <BackToTop />
  </div>
</template>

<script>
import TheHeader from "../components/TheHeader.vue";
import TheFooter from "../components/TheFooter.vue";
import WhatsappWidget from "../components/WhatsappWidget.vue";
import BackToTop from "../components/BackToTop.vue";
import AlertSlideDown from "../components/AlertSlideDown.vue";
import AlertOverlay from "../components/AlertOverlay.vue";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { mapActions,mapGetters } from 'vuex'

export default {
  name: "app",
  components: {
    TheHeader,
    TheFooter,
    WhatsappWidget,
    BackToTop,
    AlertSlideDown,
    AlertOverlay,
    Loading
  },
  mounted() {
    this.loadConfiguration();
    this.loadCategories();
    this.loadBrands();
  },
  computed: {
    ...mapGetters({
      customStyles: 'ecommerce/customStyles',
      loading: 'ecommerce/loading'
    }),
    catalogView:{
      get(){
        return this.$route.name == 'Catalog'
      }
    }
  },
  watch: {
    'customStyles': {
        handler: function (a) {
            if(a !== undefined){
                document.documentElement.style.setProperty('--primary-color', a.colorPrimary);
                document.documentElement.style.setProperty('--secondary-color', a.colorSecondary);
                document.documentElement.style.setProperty('--accent-color', a.colorAccent);
                document.documentElement.style.setProperty('--accent-color-dark', a.colorAccentDark);
                document.documentElement.style.setProperty('--accent-color-light', a.colorAccentLight);
                document.documentElement.style.setProperty('--alert-color', a.colorAlert);
            }
        },
        immediate: true
    }
  },
  methods: {
    ...mapActions({
      loadProducts: 'ecommerce/loadProducts',
      loadCategories: 'ecommerce/loadCategories',
      loadConfiguration: 'ecommerce/loadConfiguration',
      loadBrands: 'ecommerce/loadBrands'
    }),
  },
};
</script>

<style scoped>

.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: #fff;
}
.router-view-store {
  margin-top: 150px;
}
.router-view-catalog {
  margin-top: 100px;
}
@media (max-width: 1199px) {
  .router-view-store {
    margin-top: 100px;
  }
}
@media (max-width: 768px) {
  .router-view-catalog {
    margin-top: 70px;
  }
}
</style>
