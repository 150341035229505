export default function () {
  return {
    configuration: {fillers:''},
    loading: false,
    products: [],
    featured: [],
    categories: [],
    categoryList: [],
    brands: [],
    attributes: [],
    product: {},
    basket: [],
    order: {},
    user: {},
    searchdata:{
      filters:{
          category: [],
          brand: [],
          attList: [],
      },
      pagination:{
          pageNumber: 1,
          totalPages: 1,
          itemCount: 8,
          sortMethod: 'abc'
      },
      q:'',
    },
    errors:{
      message:'',
      regError: false,
      loginError: false,
    },
    successes:{
      message:'',
      regSuccess: false,
      loginSuccess: false,
    },
    mp_url: {},
    alerts: {},
    catalog: {},
    passwordRecoveryResponse: {},
  }
}
