export const SET_PRODUCTS = (state, products) => {
  state.products = products;
}
export const SET_PRODUCT = (state, product) => {
  state.product = product;
}
export const SET_CATEGORIES = (state, categories) => {
  state.categories = categories;
}
export const SET_CATEGORYLIST = (state, categories) => {
  state.categoryList = categories;
}
export const SET_CONFIGURATION = (state, configuration) => {
  state.configuration = configuration;
}
export const SET_USER = (state, user) => {
  console.log(user)
  state.user = user;
  console.log(state.user)
}
export const SET_ATTRIBUTES = (state, attributes) => {
  state.attributes = attributes;
}
export const SET_BRANDS = (state, brands) => {
  state.brands = brands;
}
export const REMOVE_FILTER_BRANDS = (state, brand_id) => {
  state.searchdata.filters.brand = state.searchdata.filters.brand.filter(function (e) {
    return (e!=brand_id);
  });
}
export const SET_FILTERS = (state, filters) => {
  state.searchdata.filters = filters;
}
export const SET_FILTER_CATEGORY = (state, category) => {
  state.searchdata.filters.category.push(category);
}
export const REMOVE_FILTER_CATEGORY = (state, category_id) => {
  state.searchdata.filters.category = state.searchdata.filters.category.filter(function (e) {
    return (e!=category_id);
  });
}
export const SET_PAGINATION = (state, pagination) => {
  state.searchdata.pagination = pagination;
}
export const SET_PAGE_NUMBER = (state,number) => {
  state.searchdata.pagination.pageNumber = number;
}
export const SET_TOTAL_PAGES = (state,pages) => {
  state.searchdata.pagination.totalPages=pages;
}
export const SET_QUERY = (state, q) => {
  state.searchdata.q = q;
}
export const SET_SORT_METHOD = (state, order) => {
  state.searchdata.pagination.sortMethod = order;
}
export const SET_ITEM_COUNT = (state,count) => {
  state.searchdata.pagination.itemCount = count;
}
export const SET_FEATURED = (state, featured) => {
  state.featured = featured;
}
export const SET_FILTER_CATEGORIES = (state,categories) => {
  state.searchdata.filters.category = categories;
}
export const SET_FILTER_BRAND = (state,brands) => {
  state.searchdata.filters.brand = brands;
}
export const SET_ORDER = (state, order) => {
  state.order = order;
}
export const SET_ERROR_REGISTER = (state,error) => {
  state.errors.message=error;
  state.errors.regError=true;
}
export const SET_ERROR_LOGIN = (state,error) => {
  state.errors.message=error;
  state.errors.loginError=true;
}
export const SET_SUCCESS_REGISTER = (state,success) => {
  state.successes.message=success;
  state.successes.regSuccess=true;
}
export const SET_SUCCESS_LOGIN = (state,success) => {
  if (success=="Usuario encontrado") {
    state.successes.message="Usuario ingresado";
  }else{
    state.successes.message=success;
  }
  
  state.successes.loginSuccess=true;
}
export const SET_LOADING_STATE = (state,loading) => {
  state.loading = loading;
}
export const SET_MP_URL = (state,mp_url) => {
  state.mp_url = mp_url;
}
export const SET_ALERTS = (state,alerts) => {
  if (Object.entries(state.alerts).length==0) {
      state.alerts = alerts;
  }
}
export const SET_CATALOG = (state,catalog) => {
  state.catalog = catalog;
}
export const SET_PASSWORD_RECOVERY = (state, response) => {
  state.passwordRecoveryResponse = response;
}
export const CLOSE_ALERT_WELCOME = (state) => {
  state.alerts.welcome.active = false;
}
export const CLOSE_ALERT_NEWS = (state) => {
  state.alerts.news.active = false;
}
export const CLEAR_ORDER = (state) => {
  state.order = {};
}
export const CLEAR_BASKET = (state) => {
  state.basket = [];
}
export const CLEAR_USER = (state) => {
  state.user = {};
}
export const ADD_FILTER_ATT = (state,att) => {
  state.searchdata.filters.attList.push(att);
}
export const REMOVE_FILTER_ATT = (state,att) => {
  state.searchdata.filters.attList.splice(
      state.searchdata.filters.attList.findIndex(function(i){return i === att;})
  );
}
export const ADD_TO_BASKET = (state, payload) => {
  let index;
  index = state.basket.findIndex(function(i){return i.key === payload.key});
  if (index !== -1) {
    state.basket.forEach(item => {
        if(item.key === payload.key){
          if(typeof payload.amount == 'string'){
            if(!isNaN(payload.amount) && !isNaN(parseInt(payload.amount)) && String(parseInt(payload.amount)).length == payload.amount.length){
              item.amount = parseInt(item.amount) + parseInt(payload.amount)
            }
          }
          else{
            item.amount = parseInt(item.amount) + payload.amount
          }
        }
    });
  } else {
    state.basket.push(JSON.parse(JSON.stringify(payload)));
  }
}
export const REMOVE_FROM_BASKET = (state, item) => {
  if(item.variant.id !== undefined){
    state.basket.splice(state.basket.findIndex((elem) => elem.variant.key === item.variant.key), 1);
  } else {
    state.basket.splice(state.basket.findIndex((elem) => elem.product.id === item.product.id), 1);
  }
}
export const UPDATE_BASKET_AMOUNT = (state, payload) => {
  state.basket.forEach(item => {
      if(item.key === payload.key){
          item.amount = payload.amount;
      }
  });
}
export const CLEAN_ERRORS = (state) => {
  state.errors.message=''
  state.errors.loginError=false
}
export const CLEAN_SUCCESSES = (state) => {
  state.successes.message=''
  state.successes.loginSuccess=false
}